/* Footer
-------------------------------------------------*/
.footer-menu {
  background-color: $pink;
  border-top: 1px solid $dunkerot;
  bottom: 0px;
  display: block;
  padding: 20px 0;
  position: fixed;
  margin: 0 auto;
  text-align: center;
  width: 100%;

  a {
    font-weight: 600;
    padding: 20px;
  }
}

/*-------------------[min 768px]------------------*/
@media (min-width: 768px){
  .footer-menu {
    width: 600px;
    a {

    }
  }

}
/*-------------------[768px -> 1170px]------------------*/
@media (min-width: 768px) and (max-width: 1170px) {

}
