/* Bildblock
-------------------------------------------------*/
.prinzessinnen-bild {
  margin-top: 50px;
  img {
    border: 5px solid $dunkerot;
    border-radius: 50%;
    max-width: 250px;
  }
  a {
    &.link-zur-startseite {
      img {
        max-width: 60px;
      }
    }
  }
}

/*-------------------[min 768px]------------------*/
@media (min-width: 768px){
  .prinzessinnen-bild {
    margin-top: 150px;
    img {
      border: 10px solid $dunkerot;
      max-width: 400px;
    }
    a {
      &.link-zur-startseite {
        img {
          max-width: 80px;
        }
      }
    }
  }
}
