/* Überschrift
-------------------------------------------------*/
h1 {
  text-align: center;
  max-width: 350px;
  margin: 0 20px;
  padding-bottom: 100px;
}

/*-------------------[min 768px]------------------*/
@media (min-width: 768px){
  h1 {
    text-align: center;
    max-width: 500px;
  }
}
