/* Inhalt
-------------------------------------------------*/

.image-wrapper {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

}

.inhalt {
  margin: 40px 20px;
}



/*-------------------[min 768px]------------------*/
@media (min-width: 768px){
.inhalt {
  max-width: 600px;
}
}
/*-------------------[768px -> 1170px]------------------*/
@media (min-width: 768px) and (max-width: 1170px) {

}
